import React, { useState } from 'react'
import { Document, Page } from 'react-pdf'
import { Box, Modal } from '@mui/material'
import { GlobalWorkerOptions } from 'pdfjs-dist'
import ModalContentPdf from '../../common/components/ModalPdf'

interface ViewPdfProp {
  closePdf: () => void
  openPdf: () => void
}

// Set the workerSrc path for pdfjs-dist
GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.worker.min.js'

const TermsOfLicense = ({ closePdf, openPdf }: ViewPdfProp): JSX.Element => {
  const [numPages, setNumPages] = useState(null)

  const onDocumentLoadSuccess = ({ numPages }: any): void => {
    setNumPages(numPages)
  }

  return (
    <Modal
      open={openPdf as any}
      onClose={closePdf}
    >
      <Box>
        <ModalContentPdf title={'Terms of license'} onClose={closePdf} width={1000}>
          <div
            style={{
              width: '100%',
              height: '100%',
              margin: '0 auto'
            }}
          >
            <Document
              file="/TermsOfLicense.pdf"
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <div key={index} style={{ position: 'relative' }}>
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} width={940} />
                  {index + 1 < (numPages ?? 0) && (
                    <div
                      style={{
                        width: '90%',
                        height: '8px',
                        margin: '20px auto',
                        backgroundColor: '#ddd'
                      }}
                    />
                  )}
                </div>
              ))}
            </Document>
          </div>
        </ModalContentPdf>
      </Box>
    </Modal>
  )
}

export default TermsOfLicense
